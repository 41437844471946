/* Vendor imports */
import React from 'react'
import PropTypes from 'prop-types'

/* App imports */
import Layout from '../../components/layout'
import SEO from '../../components/seo'

import style from './index.module.less'
import Cookietext from './Cookietext'

export const aboutPropTypes = {
  data: PropTypes.shape({
  }),
}

class Cookies extends React.Component {
  static propTypes = aboutPropTypes

  render() {
    //let { } = this.props.data
    return (
      <Layout>
        <SEO
          title="Privatsphäre-Einstellungen"
          description="Privatsphäre-Einstellungen"
          path="sookies"
          contentType="article"
        />
        <div className={style.container}>
          <h1>Privatsphäre-Einstellungen</h1>
          <div className={style.content}>

            <Cookietext/>

          </div></div>
      </Layout >
    )
  }
}


export default Cookies
